.tableau-viz-container {
  overflow-x: auto; /* Allow horizontal scrolling */
  overflow-y: auto; /* Keep vertical scrolling hidden */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  width: 100%; /* Ensure it uses the full viewport width */
}

@media (max-width: 768px) {
  .tableau-viz-container {
    width: 100%;
  }
}
