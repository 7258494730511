@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@300;400;500;600;700&display=swap");

#root {
  height: 100%;
}

html,
body {
  height: 100%; /* Ensures the body takes the full height of the viewport */
  margin: 0;
  padding: 0;
  background-color: var(
    --color-background-primary
  ); /* Set the background color to black */
  color: white; /* Set default text color to white for contrast */
}

.App {
  min-height: 100vh;
  background-color: var(--color-background-primary);
  text-align: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.App::-webkit-scrollbar {
  display: none; /* Hide scrollbar for better UX */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
