.close-button {
  color: var(--color-text-primary);
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.quadrant-button {
  width: 20vw;
  height: 19vw;
  padding: 0.75rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  border-radius: 10%;
  border: none;
  overflow: hidden;
  cursor: pointer;
}

.quadrant {
  width: 100%;
  height: 100%;
}
