header {
  z-index: 1000;
}

.header-title h1 {
  font-size: 24px;
  line-height: 28.8px;
  word-wrap: break-word;
}

.header-icon {
  font-size: 24px;
  line-height: 28.8px;
  word-wrap: break-word;
}
